import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './Views/Home/Home';
import { useEffect } from 'react';
import $ from "jquery";



function App() {
    
    useEffect(() => {

        return () => {
            $(window).scroll(function () {
                if ($(this).scrollTop() > 45) {
                    $('.navbar').addClass('sticky-top shadow-sm');
                } else {
                    $('.navbar').removeClass('sticky-top shadow-sm');
                }
            });
        };
    }, []);

    return (
        <Routes>
            <Route path='/' index element={<Home />} />
        </Routes>
    );
}

export default App;
