import React from 'react'

function Footer() {
    return (
        <div className="container-fluid bg-dark text-light footer pt-5 wow fadeIn" data-wow-delay="0.1s" >
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-md-6 col-lg-4">
                        <h5 className="text-epsilon mb-4">Contactez-nous</h5>
                        <p><i className="fa fa-map-marker-alt me-3"></i>221, Avenue d e s Usines, Quartier Makomeno, CommuneLubumbashi,Haut-Katanga, République Démocratique du Congo</p>
                        <p><i className="fa fa-phone-alt me-3"></i>+243 970 519 974</p>
                        <p><i className="fa fa-envelope me-3"></i>contact@epsilon-pros.com
                        </p>
                        <div className="d-flex pt-2">
                           
                            <a className="btn btn-outline-light btn-social" href="https://web.facebook.com/epsilonpros/?locale=fr_CA&_rdc=1&_rdr"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-outline-light btn-social" href="www.instagram.com/epsilonpros/"><i className="fab fa-instagram"></i></a>
                            
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <h5 className="text-epsilon mb-4">Navigation Rapide</h5>
                        <a className="btn btn-link-epsilon" href="">A propos de Nous</a>
                        <a className="btn btn-link-epsilon" href="">Contacts</a>
                        <a className="btn btn-link-epsilon" href="">Nos services</a>
               
                    </div>

                    <div className="col-md-6 col-lg-4">
                        <h5 className="text-epsilon mb-4">Newsletter</h5>
                        <p>
                        Ne manquez aucune innovation ! Inscrivez-vous à notre newsletter pour rester informé(e) des dernières tendances, des offres exclusives et des actualités passionnantes d'Epsilon
                        </p>
                        <div className="position-relative w-100 mt-3">
                            <input className="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text" placeholder="Adresse Email Email" style={{ height: " 48px" }} />
                            <button type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2" >
                                <i className="fa fa-paper-plane text-primary fs-4" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-12 text-center text-md-start mb-3 mb-md-0">
                            &copy; <a className="border-bottom" href="#">epsilon-pros.com</a>, All Right Reserved.
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer