import React from 'react'
import logo from "../../Assets/img/epsilon-logo.svg"
function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
            <a href="index.html" className="navbar-brand p-0">
                <img src={logo} alt="Logo" />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="fa fa-bars"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto py-0">
                    <a href="/" className="nav-item nav-link active">Accueil</a>
                    <a href="#apropos" className="nav-item nav-link">A propos</a>
                    <a href="#services" className="nav-item nav-link">Services</a>
                    <a href="#equipe" className="nav-item nav-link">Equipe</a>
                    <a href="#contact" className="nav-item nav-link">Contact</a>
                </div>

            </div>
        </nav>
    )
}

export default Navbar