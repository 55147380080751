import React, { useEffect } from 'react';
import WOW from 'wowjs';
import 'animate.css/animate.css';
import 'wowjs/css/libs/animate.css';
import Navbar from '../../Components/Navbar/Navbar';
import about from "../../Assets/img/AboutUs.svg";
import newsletter from "../../Assets/img/5.svg";
import hero from "../../Assets/img/hero.png";
import avatar from "../../Assets/img/default-avatar.jpg";
import { TeamMembers } from '../../Datas';
import Footer from '../../Components/Footer/Footer';


function TeamMember({id,delay,name,role,picture = '',x,linkedin}) {
    return (
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay={delay} id={id}>
            <div className="team-item">
                <h5>{name}</h5>
                <p className="mb-4">{role}</p>
                <img className="img-fluid rounded-circle w-100 mb-4" src={avatar} alt="" />
                <div className="d-flex justify-content-center">
                    <a className="btn btn-square text-epsilon bg-white m-1" href={x}><i className="fab fa-twitter"></i></a>
                    <a className="btn btn-square text-epsilon bg-white m-1" href={linkedin}><i className="fab fa-linkedin-in"></i></a>
                </div>
            </div>
        </div>
    )
}

function WhyUS(datas) {
    return (<div className="col-sm-6 wow fadeIn" data-wow-delay={datas.delay}>
        <div className="d-flex align-items-center mb-3">
            <div className="flex-shrink-0 btn-square bg-epsilon  rounded-circle me-3">
                <i className={"fa " + datas.icon + " text-white"}></i>
            </div>
            <h6 className="mb-0">{datas.title}</h6>
        </div>
        <span>{datas.text}</span>
    </div>)
}

function ServiceBox(datas) {

    return (
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay={datas.delay}>
            <div className="service-item rounded h-100">
                <div className="d-flex justify-content-between">
                    <div className="service-icon">
                        <i className={"fa " + datas.icon + " fa-2x"}></i>
                    </div>
                    <a className="service-btn">
                        <i className="fa fa-link fa-2x"></i>
                    </a>
                </div>
                <div className="p-5">
                    <h5 className="mb-3">{datas.title}</h5>
                    <span>{datas.description}</span>
                </div>
            </div>
        </div>
    )
}
const Home = () => {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    return (
        <div className="position-relative p-0">
            {/* navbar */}
            <Navbar />

            {/* hero header */}
            <div className="bg-epsilon hero-header" id="heroheader">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 text-center text-lg-start">
                            <h1 className="text-white mb-4 animated zoomIn">A chaque marché, le meilleur talent qui correspond</h1>
                            <p className="text-white pb-3 animated zoomIn">Explorez l'Excellence avec Epsilon, Votre Partenaire de Choix pour l'Innovation et le Succès dans le Numérique.</p>
                            <a href="#contact" className="btn btn-outline-light rounded-pill border-2 py-3 px-5 animated slideInRight">Contactez-nous</a>
                        </div>
                        <div className="col-lg-6 text-center text-lg-start">
                            <img className="img-fluid animated zoomIn" src={hero} alt="hero header epsilon" />
                        </div>
                    </div>
                </div>
            </div>

            {/* about epsilon */}

            <div className="container-xxl" id="apropos">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow zoomIn" data-wow-delay="0.1s">
                            <img className="img-fluid" src={about} alt="about epsilon" />
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            {/*<div className="d-inline-block border rounded-pill text-epsilon px-4 mb-3">QUI SOMMES NOUS?</div>*/}
                            <h2 className="mb-4">Decouvrez Epsilon</h2>
                            <p className="mb-4">Epsilon est une société dont l'objet social est la création et la
                                vente des produit et service informatique, électronique dans
                                le secteur du numérique, la recherché des marches, le placement et la mise en relation des entrepreneurs.
                            </p>
                            <div className="row g-3 mb-4">
                                <div className="col-12 d-flex">
                                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-epsilon">
                                        <i className="fa fa-user-tie text-white"></i>
                                    </div>
                                    <div className="ms-4">
                                        <h6>Espilon : une equipe des solutionneurs !</h6>
                                        <span>Explorez le travail de qualité derrière Epsilon, offrant une variété de produits et services professionnels,</span>
                                    </div>
                                </div>
                                <div className="col-12 d-flex">
                                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-epsilon">
                                        <i className="fa fa-code text-white"></i>
                                    </div>
                                    <div className="ms-4">
                                        <h6>Diversité Epsilon : Web, Graphisme, Freelance !</h6>
                                        <span>Découvrez chez Epsilon une gamme variée de produits et services de qualité professionnelle, englobant solutions web, IoT, mobile, graphisme, et une plateforme de freelance pour les entrepreneurs</span>
                                    </div>
                                </div>
                            </div>
                            {/* <a className="btn btn-epsilon rounded-pill py-3 px-5 mt-2" href="">Read More</a> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* Services Epsilon */}
            <div className="container-xxl py-6" >
                <div className="container">
                    <div className="mx-auto text-center wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                        {/*<div className="d-inline-block border rounded-pill text-epsilon px-4 mb-3">Nos offres et Services</div>*/}
                        <h2 className="mb-5">Nous fournissons des solutions pour votre entreprise</h2>
                    </div>
                    <div className="row g-4">

                        <ServiceBox
                            delay="0.1s" icon="fa-shop"
                            title="Epsilon Dev" description="votre marché dédié au stockage, à la promotion et à la vente de logiciels exceptionnels concus sur mesure pour repondre à vos attentes" />

                        <ServiceBox
                            delay="0.3s" icon="fa-briefcase"
                            title="Epsilon Free-lance"
                            description="Explorez une nouvelle ère du travail flexible avec Epsilon Freelance, la plateforme où les entreprises et les particuliers se connectent avec des freelancers talentueux.  " />

                        <ServiceBox
                            delay="0.5s" icon="fa-code"
                            title="Epsilon Pros"
                            description="Votre partenaire dans la création d'applications web et mobile, de logiciels ERP, de processus d'automation, ainsi que dans le design et le graphisme." />

                    </div>
                </div>
            </div>

            {/* Why us */}
            <div className="container-xxl py-6">
                <div className="container">
                    <div className="row g-5">

                        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                            {/*<div className="d-inline-block border rounded-pill text-epsilon px-4 mb-3">Epsilon</div>*/}
                            <h2 className="mb-4">Pourquoi choisr Epsilon ? Nous sommes une agence de confiance et primée</h2>
                            <p>Choisissez Epsilon, car nous incarnons l'avenir de l'innovation numérique. Notre équipe passionnée combine expertise technique, créativité sans bornes et engagement envers votre succès.  </p>
                            <p>En collaborant avec Epsilon, vous bénéficierez d'une approche sur mesure, de solutions novatrices et d'une qualité inégalée.</p>
                            {/* <a className="btn btn-epsilon rounded-pill py-3 px-5 mt-2" href="">Lire plus</a> */}
                        </div>

                        <div className="col-lg-8">
                            <div className="row g-5">

                                <WhyUS
                                    delay="0.1s"
                                    icon="fa-cubes"
                                    title="Meilleur de l'industrie"
                                    text="Optez pour l'exceptionnel avec Epsilon, votre choix numéro un pour des solutions numériques de classe mondiale."
                                />
                                <WhyUS
                                    delay="0.3s"
                                    icon="fa-percent"
                                    title="Taux de réussite de 99%"
                                    text="Votre succès est notre norme. Bénéficiez d'un taux de réussite de 99%, assurant une performance optimale à chaque étape."
                                />
                                <WhyUS
                                    delay="0.5s"
                                    icon="fa-award"
                                    title="3e place au challenge Stop Corona Virus en 2020"
                                    text="Choisissez un partenaire récompensé. Epsilon, lauréat de la 3e place au Challenge Stop Corona Virus 2020, prouve son impact positif et durable."
                                />

                                <WhyUS
                                    delay="0.7s"
                                    icon="fa-smile-beam"
                                    title="100% satisfait"
                                    text="Votre satisfaction est notre priorité absolue. À chaque projet, notre engagement est de vous laisser 100% satisfait."
                                />
                                <WhyUS
                                    delay="0.9s"
                                    icon="fa-user-tie"
                                    title="Une equipe qualifie pour vos besoins"
                                    text="Collaborez avec des experts dédiés. L'équipe qualifiée d'Epsilon est prête à relever tous vos défis avec un savoir-faire exceptionnel."
                                />
                                <WhyUS
                                    delay="0.11s"
                                    icon="fa-headset"
                                    title="Assistance Technique 24/7"
                                    text="Votre tranquillité, notre engagement. Bénéficiez d'une assistance technique ininterrompue 24/7 pour une expérience sans souci."
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Teamm */}

            <div className="container-xxl py-5 n6" id="equipe">
                <div className="container">
                    <div className="mx-auto text-center wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "900px" }}>
                        {/*<div className="d-inline-block border rounded-pill text-epsilon px-4 mb-3">Notre équipe</div>*/}
                        <h2 className="mb-5">Découvrez la force unifiée derrière notre succès – une équipe dévouée, prête à transformer vos idées en réalité</h2>
                    </div>
                    <div className="row g-4 d-flex justify-content-center">
                        {TeamMembers.map(item => (
                            <TeamMember
                                id={item.id}
                                delay={`0.${item.id}s`}
                                name={item.name}
                                role={item.role}
                                x={item.x}
                                picture={item.picture}
                                linkedin={item.linkedin}
                            />
                        ))}
                    </div>
                </div>
            </div>

            {/* CTA Mail us */}
            <div className="bg-epsilon mt-6 wow fadeInUp py-5" id="contact" data-wow-delay="0.1s">
                <div className="container px-lg-5">
                    <div className="row align-items-center" style={{ height: "250px;" }}>
                        <div className="col-12 col-md-6">
                            <h3 className="text-white">Prêt à passer à l'action ?</h3>
                            <small className="text-white ">Nous sommes là pour vous aider à réaliser vos objectifs. Utilisez le formulaire ci-dessous pour nous contacter et discuter de votre projet. Ensemble, donnons vie à votre vision !</small>

                            <form className="mt-4">
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" id="name" placeholder="Your Name" />
                                            <label htmlFor="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="email" className="form-control" id="email" placeholder="Your Email" />
                                            <label htmlFor="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <select className="form-select" aria-label="Default select example">
                                                <option selected>De quoi voulez-vous parler ?</option>
                                                <option value="1">Conception d'une application </option>
                                                <option value="2">Vendre/stocker/promouvoir une application</option>
                                                <option value="3">Devenir Freelancer</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea className="form-control" placeholder="Leave a message here" id="message" style={{ height: "100px" }}></textarea>
                                            <label htmlFor="message">Message</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-light w-100 py-3" type="submit">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 text-center mb-n5 d-none d-md-block">
                            <img className="img-fluid mt-5" alt="News Letter" src={newsletter} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer */}
            <Footer />

        </div>
    )
}

export default Home