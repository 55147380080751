export const TeamMembers = [
    {
        id : 1,
        name : "Harold Kabongo",
        role : "Gérant",
        picture : "epsilon",
        x:"",
        linkedin:"",
    },
    
    {
        id : 2,
        name : "Aime Nzolo",
        role : "Gérant",
        picture : "epsilon",
        x:"https://twitter.com/aimenzolo",
        linkedin:"https://www.linkedin.com/in/aime-nzolo-889023199/",
    },
    {
        id : 3,
        name : "Tabitha Kasengela",
        role : "Secrétaire",
        picture : "epsilon",
        x:"",
        linkedin:"",
    },
    {
        id : 4,
        name : "Donald Kitenge",
        role : "Developpeur Front-end",
        picture : "",
        x:"",
        linkedin:"",
    },
    {
        id : 5,
        name : "Merdi Kayembe",
        role : "Designer",
        picture : "",
        x:"",
        linkedin:"",
    },
]